import { memo, useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useAuth } from 'contexts';

const UserMenu = ({ anchorEl, onClose: onMenuClose, open }) => {
  const { logout } = useAuth();

  const handleLogout = useCallback(() => {
    onMenuClose();
    logout();
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onMenuClose}
      sx={({ palette }) => ({
        '& .MuiPaper-root': {
          background: palette.background.grey
        }
      })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <MenuItem onClick={handleLogout}>
        Logout
      </MenuItem>
    </Menu>
  );
};

UserMenu.displayName = 'UserMenu';

export default memo(UserMenu);