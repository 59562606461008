import { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useAssetsSummary } from 'containers/AssetManagement';

const RegisterAssetForm = () => {
  const {
    data: { assets_available_to_register = [] } = {}
  } = useAssetsSummary();

  const { control } = useFormContext();

  const assetsAvailableToRegister = useMemo(() => assets_available_to_register?.map((assetId) => ({
    label: assetId,
    value: assetId
  })), [assets_available_to_register?.length]);

  return (
    <>
      <Controller
        name="assetId"
        rules={{ required: 'Asset ID is required' }}
        control={control}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.assetId?.message;
          return (
            <FormControl
              fullWidth
              sx={{ mb: 2, mt: 1 }}
              error={!!fieldErrorMessage}
            >
              <InputLabel>Asset ID</InputLabel>
              <Select
                error={!!fieldErrorMessage}
                label="Asset ID"
                placeholder="Select Asset ID"
                {...field}
              >
                {assetsAvailableToRegister.map(({ value, label }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </Select>
              {!!fieldErrorMessage && <FormHelperText>{fieldErrorMessage}</FormHelperText>}
            </FormControl>
          );
        }}
      />
      <Controller
        name="qtrackId"
        control={control}
        rules={{ required: 'QTrack ID is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.qtrackId?.message;
          return (
            <TextField
              fullWidth
              placeholder="Enter QTrack ID"
              label="QTrack ID"
              type="text"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
    </>
  );
};

RegisterAssetForm.displayName = 'RegisterAssetForm';

export default memo(RegisterAssetForm);