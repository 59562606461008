import { memo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDuration, intervalToDuration } from 'date-fns';

export const customFormatDuration = ({ start, end }) => {
  const durations = intervalToDuration({ start, end });
  return formatDuration(durations);
};

const AssetDialog = ({ open, onClose, asset = {} }) => {
  const navigate = useNavigate();

  const navigateToReportGeneration = useCallback(() => {
    navigate(`/report-generation?asset_id=${asset?.asset_id}`);
  }, [asset?.asset_id, navigate]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Asset data</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">
          <b>Asset ID:</b> {asset?.asset_id}
        </Typography>
        <Typography variant="subtitle2">
          <b>Latitude:</b> {asset?.data?.lat}
        </Typography>
        <Typography variant="subtitle2">
          <b>Longitude:</b> {asset?.data?.lng}
        </Typography>
        <Typography variant="subtitle2">
          <b>Tracker ID:</b> {asset?.qtrack_id}
        </Typography>
        <Typography variant="subtitle2">
          <b>Total Usage:</b> {asset?.data?.operation_time?.minutes_of_asset_run
            // eslint-disable-next-line no-unsafe-optional-chaining
            ? customFormatDuration({ start: 0, end: asset?.data?.operation_time?.minutes_of_asset_run * 60 * 1000 })
            : '-'}
        </Typography>
        <Typography variant="subtitle2">
          <b>Backup Battery:</b> {asset?.data?.rhi_int_batt_v.toFixed(2)} Volts
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={navigateToReportGeneration}
        >
          Generate Report...
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssetDialog.displayName = 'AssetDialog';

export default memo(AssetDialog);