import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { isNull } from 'lodash';
import { Map, createMarkerClusterIcon } from 'rhi-ui';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { axiosInstance } from 'services';
import { useConfiguration } from 'hooks';
import { Box, Typography } from '@mui/material';
import AssetDialog from './AssetDialog';

const MapComponent = () => {
  const [selectedAssetData, setSelectedAssetData] = useState(null);
  const { mapboxToken } = useConfiguration();

  const { data: { data: assets = [] } = {} } = useQuery({
    queryKey: ['registered_assets_details'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/registered_assets_details');
      return response;
    },
    refetchInterval: 10000
  });

  return (
    <>
      <Map mapboxToken={mapboxToken}>
        <MarkerClusterGroup
          disableClusteringAtZoom={17}
          spiderfyOnMaxZoom
          removeOutsideVisibleBounds
          chunkedLoading
          iconCreateFunction={createMarkerClusterIcon}
        >
          {(assets || [])?.map((asset) => (
            <Marker
              key={asset.asset_id}
              position={[asset?.data?.lat || 0, asset?.data?.lng || 0]}
              eventHandlers={{
                click: () => setSelectedAssetData(asset)
              }}
            >
              <Tooltip
                permanent
                offset={[12, -5]}
                opacity={1}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    mb={-0.5}
                  >{asset.asset_id}
                  </Typography>
                  <Typography variant="subtitle2">{asset.asset_name}</Typography>
                </Box>
              </Tooltip>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </Map>
      <AssetDialog
        open={!isNull(selectedAssetData)}
        onClose={() => setSelectedAssetData(null)}
        asset={selectedAssetData}
      />
    </>
  );
};

export default MapComponent;
