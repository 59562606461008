import { memo, useState } from 'react';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import AddAssetDialog from './AddAssetDialog';

const AddAssetButton = () => {
  const [isAddAssetDialogOpen, toggleAddAssetDialog] = useState(false);
  return (
    <>
      <Button
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => toggleAddAssetDialog(true)}
      >
        New Asset
      </Button>
      <AddAssetDialog isOpen={isAddAssetDialogOpen} onClose={() => toggleAddAssetDialog(false)} />
    </>
  );
};

AddAssetButton.displayName = 'AddAssetButton';

export default memo(AddAssetButton);