import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    // Memoize the router props to prevent unnecessary re-renders
    const routerProps = useMemo(() => ({
      location,
      navigate,
      params
    }), [location, navigate, params]);

    return <Component {...routerProps} {...props} />;
  };

  return ComponentWithRouterProp;
};

export default withRouter;