import { memo, useCallback, useMemo } from 'react';
import { format, parse } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from 'services';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import useRegisteredAssets from '../hooks/useRegisteredAssets';

const isValidDateTime = (value, originalValue, schema, dataTimeFormat) => {
  if (schema.isType(value)) {
    return value;
  }
  const result = parse(originalValue, dataTimeFormat, new Date());
  return result;
};

const ReportGenerationFormSchema = yup.object().shape({
  assetIds: yup.array().min(1, 'Select at least one Asset'),
  startDate: yup.date()
    .transform((...params) => isValidDateTime(...params, 'dd.MM.yyyy'))
    .typeError('Please enter a valid date')
    .required(),
  endDate: yup.date()
    .transform((...params) => isValidDateTime(...params, 'dd.MM.yyyy'))
    .typeError('Please enter a valid date')
    .required(),
  startTime: yup.date()
    .transform((...params) => isValidDateTime(...params, 'hh:mm a'))
    .typeError('Please enter a valid time')
    .required(),
  endTime: yup.date()
    .transform((...params) => isValidDateTime(...params, 'hh:mm a'))
    .typeError('Please enter a valid time')
    .required()
});

const ReportGenerationForm = () => {
  const { data: { assets_registered = [] } = {} } = useRegisteredAssets();

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const defaultAssetId = searchParams.get('asset_id');

  const { handleSubmit, control, reset: resetForm } = useForm({
    defaultValues: {
      assetIds: defaultAssetId ? [defaultAssetId] : [],
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: yupResolver(ReportGenerationFormSchema)
  });

  const { mutate: generateReportMutation } = useMutation({
    mutationFn: async ({ assetIds, startTime, endTime }) => {
      if (!assetIds?.length || !startTime || !endTime) return;

      const assets_selected = (assetIds || []).filter(Boolean);

      const result = await axiosInstance.post('/v1/generate_reports', {
        assets_selected,
        start_timestamp: startTime,
        stop_timestamp: endTime,
        user_email: 'developer@rajant.com'
      });
      return result;
    },
    onSuccess: () => {
      enqueueSnackbar('Report generation started', {
        variant: 'success',
        description: 'You will receive email with instruction to download reports.'
      });
      resetForm();
    },
    onError: ({ response }) => {
      enqueueSnackbar('Cannot start report generation', {
        variant: 'error',
        description: response?.data?.detail || '',
        withCloseIcon: true
      });
    }
  });

  const registeredAssetsOptions = useMemo(() => assets_registered?.map((assetId) => ({
    label: assetId,
    value: assetId
  })), [assets_registered?.length]);

  const handleReportGeneration = useCallback(({
    startTime, endTime, startDate, endDate, assetIds
  }) => {
    const startDateFormatted = format(new Date(startDate), 'yyyy-MM-dd');
    const endDateFormatted = format(new Date(endDate), 'yyyy-MM-dd');
    const startTimeFormatted = format(new Date(startTime), 'HH:mm:ss.sss');
    const endTimeFormatted = format(new Date(endTime), 'HH:mm:ss.sss');

    const startISOString = `${startDateFormatted}T${startTimeFormatted}Z`;
    const endISOString = `${endDateFormatted}T${endTimeFormatted}Z`;

    generateReportMutation({ startTime: startISOString, endTime: endISOString, assetIds });
  }, []);

  return (
    <Paper>
      <form onSubmit={handleSubmit(handleReportGeneration)}>
        <Controller
          name="assetIds"
          control={control}
          multiple
          render={({ field, formState }) => {
            const { errors } = formState;
            const fieldErrorMessage = errors?.assetIds?.message;
            return (
              <FormControl
                fullWidth
                error={!!fieldErrorMessage}
                sx={{ mb: 1 }}
              >
                <InputLabel>Asset(s)</InputLabel>
                <Select
                  label="Asset(s)"
                  error={!!fieldErrorMessage}
                  multiple
                  MenuProps={{ sx: { maxHeight: '300px' } }}
                  {...field}
                >
                  {registeredAssetsOptions.map(({ value, label }) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {!!fieldErrorMessage && <FormHelperText>{fieldErrorMessage}</FormHelperText>}
              </FormControl>
            );
          }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={500}
          mb={1}
        >
          Start Date and Time
        </Typography>
        <Grid
          container
          columnSpacing={2}
          mb={1}
        >
          <Grid item xs={6}>
            <Controller
              name="startDate"
              control={control}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldErrorMessage = errors?.startDate?.message;
                return (
                  <DatePicker
                    {...field}
                    sx={{ width: '100%' }}
                    label="Enter start date"
                    slotProps={{
                      textField: { error: !!fieldErrorMessage, helperText: fieldErrorMessage }
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldErrorMessage = errors?.startTime?.message;
                return (
                  <TimePicker
                    {...field}
                    sx={{ width: '100%' }}
                    label="Enter start time"
                    slotProps={{
                      textField: { error: !!fieldErrorMessage, helperText: fieldErrorMessage }
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          mb={1}
        >
          End Date and Time
        </Typography>
        <Grid
          container
          columnSpacing={2}
          mb={2}
        >
          <Grid item xs={6}>
            <Controller
              name="endDate"
              control={control}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldErrorMessage = errors?.endDate?.message;
                return (
                  <DatePicker
                    {...field}
                    sx={{ width: '100%' }}
                    label="Enter end date"
                    slotProps={{
                      textField: { error: !!fieldErrorMessage, helperText: fieldErrorMessage }
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="endTime"
              control={control}
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldErrorMessage = errors?.endTime?.message;
                return (
                  <TimePicker
                    {...field}
                    sx={{ width: '100%' }}
                    label="Enter end time"
                    slotProps={{
                      textField: { error: !!fieldErrorMessage, helperText: fieldErrorMessage }
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
        >
          Generate report
        </Button>
      </form>
    </Paper>
  );
};

ReportGenerationForm.displayName = 'ReportGenerationForm';

export default memo(ReportGenerationForm);