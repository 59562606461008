import { memo, useEffect, useState } from 'react';
import { AuthProvider } from 'contexts';

const AuthWrapper = ({ children }) => {
  const [authorizedState, setAuthorizedState] = useState({});
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const handleSetAuthorizedState = (authState = {}) => {
    localStorage.setItem('auth', JSON.stringify({ ...authState }));
    setAuthorizedState(authState);
  };

  const handleUserLogout = () => {
    localStorage.clear();
    setAuthorizedState();
  };

  useEffect(() => {
    const authParams = localStorage.getItem('auth');
    handleSetAuthorizedState(JSON.parse(authParams));
    setIsAuthLoading(false);
  }, []);

  return (
    <AuthProvider
      value={{
        authorizedState,
        isAuthLoading,
        setAuthorizedState: handleSetAuthorizedState,
        logout: handleUserLogout
      }}
    >
      {children}
    </AuthProvider>
  );
};

AuthWrapper.displayName = 'AuthWrapper';

export default memo(AuthWrapper);