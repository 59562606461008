import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from 'containers/App/App';
import reportWebVitals from './reportWebVitals';
// TODO: Include fonts in the rhi-ui's ThemeWrapper
import 'rhi-ui/fonts.css'; // eslint-disable-line

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

reportWebVitals();
