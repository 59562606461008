import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'services';

const useRegisteredAssets = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['registered_assets'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/registered_assets');
      return response.data;
    }
  });

  return {
    data,
    isLoading
  };
};

export default useRegisteredAssets;