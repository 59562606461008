import {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { last } from 'lodash';

const NAVIGATION_TABS = {
  dashboard: {
    path: 'dashboard',
    id: 'dashboard',
    label: 'Dashboard',
    disabled: false,
    exact: true
  },
  reportGeneration: {
    path: 'report-generation',
    id: 'report-generation',
    label: 'Report Generation',
    disabled: false,
    exact: true
  }
};

const NavigationTabs = () => {
  const [activeTab, setActiveTab] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const locationPathname = last(location.pathname.split('/').filter(Boolean));
  const defaultActiveKey = Object.values(NAVIGATION_TABS)?.find(({ path }) => path === locationPathname)?.path;

  useEffect(() => {
    setActiveTab(defaultActiveKey);
  }, [defaultActiveKey]);

  const handleTabChange = useCallback((event, path) => {
    navigate(path);
    setActiveTab(path);
  }, []);

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      textColor="secondary"
      indicatorColor="gradient"
    >
      {Object.values(NAVIGATION_TABS).map(({
        id, label, path
      }) => (
        <Tab
          value={path}
          label={label}
          key={id}
        />
      ))}
    </Tabs>
  );
};

NavigationTabs.displayName = 'NavigationTabs';

export default memo(NavigationTabs);