import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'services';

const useAssetsSummary = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['asset_summary'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/asset_summary');
      return response.data;
    }
  });

  return {
    data,
    isLoading
  };
};

export default useAssetsSummary;