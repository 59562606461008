import React, { Suspense, lazy } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { MainLayout } from 'layouts';
import DashBoardPage from 'containers/Dashboard';
import BulkConfigurationPage from 'containers/BulkConfiguration';
import ReportGenerationPage from 'containers/ReportGeneration';
import { MainWrapper } from 'wrappers';
import { useAuth } from 'contexts';
import { Backdrop } from '@mui/material';

const LogIn = lazy(() => import('../containers/LogIn/index'));
const NotFound404 = lazy(() => import('../containers/DefaultPages/404'));

const ProtectedRoute = () => {
  const location = useLocation();
  const { authorizedState } = useAuth();

  if (authorizedState?.loggedIn && location.pathname === '/') {
    return <Navigate to="/dashboard" replace />;
  }
  return authorizedState?.loggedIn ? <Outlet /> : <Navigate to="/" replace />;
};

const Router = () => (
  <MainWrapper>
    <Suspense fallback={(
      <Backdrop open invisible>
        <CircularProgress size={24} />
      </Backdrop>
    )}
    >
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<DashBoardPage />} />
            <Route path="/bulk-configuration" element={<BulkConfigurationPage />} />
            <Route path="/report-generation" element={<ReportGenerationPage />} />
          </Route>
        </Route>
        <Route path="/" element={<LogIn />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </Suspense>
  </MainWrapper>
);

export default Router;
