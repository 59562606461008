import { memo } from 'react';
import { Box } from '@mui/material';
import ReportGenerationForm from './components/ReportGenerationForm';

const ReportGenerationPage = () => (
  <Box
    display="flex"
    flexDirection="column"
    width={{ sx: '60%', md: '50%' }}
  >
    <ReportGenerationForm />
  </Box>
);

ReportGenerationPage.displayName = 'ReportGenerationPage';

export default memo(ReportGenerationPage);