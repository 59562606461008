import { memo, useState } from 'react';
import { Button } from '@mui/material';
import RegisterAssetDialog from './RegisterAssetDialog';

const RegisterAssetButton = () => {
  const [isRegisterAssetDialogOpen, toggleRegisterAssetDialog] = useState(false);
  return (
    <>
      <Button
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        onClick={() => toggleRegisterAssetDialog(true)}
      >
        Register Asset
      </Button>
      <RegisterAssetDialog
        isOpen={isRegisterAssetDialogOpen}
        onClose={() => toggleRegisterAssetDialog(false)}
      />
    </>
  );
};

RegisterAssetButton.displayName = 'RegisterAssetButton';

export default memo(RegisterAssetButton);