import axios from 'axios';

const { REACT_APP_API_URL = '' } = process.env;
const {
  API_URL: apiUrl = REACT_APP_API_URL
} = window;

const axiosInstance = axios.create({
  baseURL: apiUrl
});

export default axiosInstance;