import React from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'HOCs';

const MainWrapper = ({
  rtl,
  children
}) => {
  const location = useLocation();
  const direction = location.pathname === '/' ? 'ltr' : rtl?.direction;
  return (
    <Box
      className={`${direction}-support`}
      dir={direction}
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default withRouter(MainWrapper);
