import { createContext, useContext } from 'react';

const AuthContext = createContext({
  authorizedState: {},
  isAuthLoading: false,
  setAuthorizedState: () => {},
  logout: () => {}
});

export const AuthProvider = AuthContext.Provider;

export const useAuth = () => useContext(AuthContext);