import { React, Suspense, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Stack
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import MapComponent from './MapComponent';
import DataTable from './DataTable';

const MapDataTable = () => {
  const [view, setView] = useState('map');
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden'
    }}
    >
      <Grid item>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={0}
        >
          <IconButton
            size="small"
            onClick={() => setView('map')}
            color={view === 'map' ? 'primary' : 'default'}
            variant={view === 'map' ? 'outlined' : 'text'}
            aria-label="View Map"
          >
            <MapIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => setView('table')}
            color={view === 'table' ? 'primary' : 'default'}
            variant={view === 'table' ? 'outlined' : 'text'}
            aria-label="View Table"
          >
            <BackupTableIcon />
          </IconButton>
        </Stack>
      </Grid>
      <Suspense fallback={<div>Loading...</div>}>
        {view === 'map' ? <MapComponent /> : <DataTable />}
      </Suspense>
    </Box>
  );
};

MapDataTable.displayName = 'MapDataTable';

export default (MapDataTable);