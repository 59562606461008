import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

const AddAssetForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        name="assetId"
        control={control}
        rules={{ required: 'Asset ID is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.assetId?.message;
          return (
            <TextField
              fullWidth
              sx={{ mb: 2, mt: 1 }}
              placeholder="Enter Asset ID"
              label="Asset ID"
              type="text"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
      <Controller
        name="assetName"
        control={control}
        rules={{ required: 'Asset Name is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.assetName?.message;
          return (
            <TextField
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
              placeholder="Enter Asset Name"
              label="Asset Name"
              type="text"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
      <Controller
        name="assetModelNumber"
        control={control}
        rules={{ required: 'Asset Model Number is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.assetModelNumber?.message;
          return (
            <TextField
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
              placeholder="Enter Asset Model Number"
              label="Asset Model Number"
              type="text"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
      <Controller
        name="assetSerialNumber"
        control={control}
        rules={{ required: 'Asset Serial Number is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.assetSerialNumber?.message;
          return (
            <TextField
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
              placeholder="Enter Asset Serial Number"
              label="Asset Serial Number"
              type="text"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
      <Controller
        name="operationMinutes"
        control={control}
        rules={{ required: 'Total Minutes of Operation is required' }}
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldErrorMessage = errors?.operationMinutes?.message;
          return (
            <TextField
              fullWidth
              placeholder="Enter Total Minutes of Operation"
              label="Total Minutes of Operation"
              type="number"
              error={!!fieldErrorMessage}
              helperText={fieldErrorMessage}
              {...field}
            />
          );
        }}
      />
    </>
  );
};

AddAssetForm.displayName = 'AddAssetForm';

export default memo(AddAssetForm);