import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { memo, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { axiosInstance } from 'services';
import AddAssetForm from './AddAssetForm';

const AddAssetDialog = ({ isOpen = false, onClose = () => {} }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: addAssetMutation } = useMutation({
    mutationFn: async ({
      assetId: asset_id,
      operationMinutes,
      assetName: asset_name,
      assetModelNumber: asset_model_number,
      assetSerialNumber: asset_serial_number
    }) => {
      const result = await axiosInstance.post('/v1/add_asset', {
        asset_id,
        asset_model_number,
        asset_name,
        asset_serial_number,
        minutes_of_operations_before_registration: parseInt(operationMinutes)
      });
      return result;
    },
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(['asset_summary'], (({
        assets_registered_count,
        assets_available_to_register_count,
        assets_available_to_register
      }) => ({
        assets_registered_count,
        assets_available_to_register_count: assets_available_to_register_count + 1,
        assets_available_to_register: [...assets_available_to_register, assetId]
      })));
      enqueueSnackbar('Asset has been added', {
        variant: 'success'
      });
    },
    onError: ({ response }) => {
      enqueueSnackbar('Cannot add an asset', {
        variant: 'error'
      });
    }
  });

  const addAssetForm = useForm({
    defaultValues: {
      assetId: '',
      assetName: '',
      assetModelNumber: '',
      assetSerialNumber: '',
      operationMinutes: ''
    },
    reValidateMode: 'onChange',
    mode: 'onChange'
  });
  const { handleSubmit, reset: resetForm } = addAssetForm;

  const handleDialogClose = useCallback(() => {
    resetForm();
    onClose();
  }, []);

  const handleAddAsset = useCallback((fieldValues) => {
    addAssetMutation(fieldValues);
    handleDialogClose();
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Add Asset</DialogTitle>
      <FormProvider {...addAssetForm}>
        <form onSubmit={handleSubmit(handleAddAsset)}>
          <DialogContent>
            <AddAssetForm />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

AddAssetDialog.displayName = 'AddAssetDialog';

export default memo(AddAssetDialog);