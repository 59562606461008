import { memo } from 'react';
import { Header as RHIHeader } from 'rhi-ui';
import TRentaLogoWhite from 'assets/images/TRentaLogoWhite.png';
import { useAuth } from 'contexts';
import { NavigationTabs, UserMenu } from './components';

const Header = () => {
  const { authorizedState } = useAuth();
  return (
    <RHIHeader
      UserMenu={UserMenu}
      username={authorizedState?.firstName}
      logo={(
        <img
          src={TRentaLogoWhite}
          alt="QTrack"
          style={{ height: '70%' }}
        />
      )}
    >
      <NavigationTabs />
    </RHIHeader>
  );
};

Header.displayName = 'Header';

export default memo(Header);