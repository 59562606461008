import { memo, useMemo, useState } from 'react';
import { axiosInstance } from 'services';
import { MaterialReactTable } from 'material-react-table';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';

const DataTable = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  const { data: { data: assets = [] } = {} } = useQuery({
    queryKey: ['registered_assets_details'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/registered_assets_details');
      return response;
    },
    refetchInterval: 10000
  });

  const columns = useMemo(() => [
    {
      id: 'asset_id',
      header: 'Asset ID',
      accessorKey: 'asset_id',
      size: 200,
      enableClickToCopy: true
    },
    {
      id: 'asset_serial_number',
      header: 'Serial Number',
      accessorKey: 'asset_serial_number',
      size: 200,
      enableClickToCopy: true
    },
    {
      id: 'asset_model_number',
      header: 'Model',
      accessorKey: 'asset_model_number',
      size: 200
    },
    {
      id: 'tracker_utilization',
      header: 'Total Utilization',
      accessorKey: 'tracker_utilization',
      enableColumnFilter: false,
      accessorFn: (row) => row.operation_time?.minutes_of_asset_run ?? -1,
      size: 50,
      Cell: ({ row }) => {
        const minutes = row.original.operation_time?.minutes_of_asset_run;
        if (!minutes) {
          return 'N/A';
        }
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        if (hours === 0) {
          return `${mins} mins`;
        } if (mins === 0) {
          return `${hours} hrs`;
        }
        return `${hours} hrs ${mins} mins`;
      }
    },
    {
      id: 'coordinates',
      header: 'Last Known Location',
      accessorKey: 'last_updated_timestamp',
      enableColumnFilter: false,
      Cell: ({ row, cell }) => {
        const lastUpdatedTimestamp = cell.getValue();
        const lat = row.original.data?.lat?.toFixed(5) ?? 'N/A';
        const lon = row.original.data?.lng?.toFixed(5) ?? 'N/A';
        const lastReported = lastUpdatedTimestamp
          ? `${format(new Date(lastUpdatedTimestamp), 'yyyy-MM-dd HH:mm:ss')} (local)`
          : 'N/A';

        return (
          <Box display="flex" flexDirection="column">
            Lat: {lat}, Lon: {lon}
            <br />
            <Typography variant="body3" color="secondary">
              Reported: {lastReported}
            </Typography>
          </Box>
        );
      },
      size: 240
    }
  ], []);

  return (
    <MaterialReactTable
      columns={columns}
      autoResetPageIndex={false}
      data={assets}
      onPaginationChange={setPagination}
      state={{ pagination }}
      enableDensityToggle={false}
      enableColumnOrdering
      enableStickyHeader
      muiTableContainerProps={({
        table
      }) => ({
        sx: {
          // eslint-disable-next-line max-len
          height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
        }
      })}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          height: '100%',
          padding: 0,
          overflow: 'hidden'
        }
      }}
    />
  );
};

export default memo(DataTable);
