import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'services';

const useAvailableTrackers = () => {
  const { data: trackers, isLoading } = useQuery({
    queryKey: ['trackers_available'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/trackers_available');
      return response.data;
    }
  });

  return {
    trackers,
    isLoading
  };
};

export default useAvailableTrackers;