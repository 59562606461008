import React from 'react';
import { Footer as RHIFooter } from 'rhi-ui';
import LogoExtended from 'assets/images/logo_extended.png';

const Footer = () => (
  <RHIFooter
    logo={(
      <img
        src={LogoExtended}
        alt="QTrack"
        style={{ height: '60%' }}
      />
    )}
  />
);

export default Footer;